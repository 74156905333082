/**
 * Utils: Back-end
 */

import config from '../config'

/**
 * POST to get a pass by ID
 */
export const searchPass = async (id) => {
  if(window._airlineShortName.includes("${")){
    window._airlineShortName = process.env.REACT_APP_AIRLINE
    window._passEnv = process.env.REACT_APP_ENV
  }
  return await requestApi('/pass/search', 'POST', { id, airline: window._airlineShortName, env: window._passEnv })
}

/**
 * POST to send a pass URL via email
 */
export const emailPass = async (id,email,lang) => {
  if(window._airlineShortName.includes("${")){
    window._airlineShortName = process.env.REACT_APP_AIRLINE
    window._passEnv = process.env.REACT_APP_ENV
  }
  return await requestApi('/pass/email', 'POST', { id, airline: window._airlineShortName, email:email, lang:lang, env: window._passEnv })
}

/**
 * POST to send a pass URL via SMS
 */
export const smsPass = async (id,mobileNumber,lang) => {
  if(window._airlineShortName.includes("${")){
    window._airlineShortName = process.env.REACT_APP_AIRLINE
    window._passEnv = process.env.REACT_APP_ENV
  }
  return await requestApi('/pass/sms', 'POST', { id, airline: window._airlineShortName, to:mobileNumber, lang:lang, env: window._passEnv })
}

/**
 * API request to call the backend
 */
export const requestApi = async (
  path = '',
  method = 'GET',
  data = null,
  headers = {}) => {

  // Check if API URL has been set
  if (!config?.domains?.api) {
    throw new Error(`Error: Missing API Domain – Please add the API domain from your serverless Express.js back-end to this front-end application.  You can do this in the "site" folder, in the "./config.js" file.  Instructions are listed there and in the documentation.`)
  }

  // Prepare URL
  if (!path.startsWith('/')) {
    path = `/${path}`
  }
  const url = `${config.domains.api}${path}`

  // Set headers
  headers = Object.assign(
    { 'Content-Type': 'application/json' },
    headers
  )

  // Default options are marked with *
  const response = await fetch(url, {
    method: method.toUpperCase(),
    mode: 'cors',
    cache: 'no-cache',
    headers,
    body: data ? JSON.stringify(data) : null
  })

  if (response.status < 200 || response.status >= 300) {
    const error = await response.json()
    throw new Error(error.error)
  }

  return await response.json()
}